
type resultText = { id: number, text: string, customerReason?: string };

export type SP8FeedbackCustomer = {
    firstName: string,
    lastName: string,
    orderNumber: string,
    email: string,
    phoneNumber: string
}
export interface SP8Feedback {
    date: Date,
    dateEnd: Date,
    formType: string,
    customer: SP8FeedbackCustomer,
    feedback: {
        selectedFeedback: resultText,
    },
    terms: {
        acceptedTerms: string[]
    }
}

export interface DepositFeedback {
    formType: string,
    customer: {
        orderNumberDeposit: string,
        orderNumberDevice: string
        email: string,
    }
}

export enum Priority {
    BStockOption = 'bstock_option',
    Urgent = 'urgent',
    Normal = 'normal',
    Wait = 'wait',
    Share = 'share'
}

export interface OrderStatus {
    priority?: Priority,
    rank?: number,
    state?: 'open' | 'closed' | 'cancelled',
    instructionsUrl?: string,
    orderBlockedBy?: string,
    errorMessages?: string,
    loadDevice?: string,
    shipmentInfoText?: string
}