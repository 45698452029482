<script setup lang="ts">
import { OrderStatus, Priority, SP8FeedbackCustomer } from "~/server/backend/src/api/public-api/helper/interfaces";
import { serverStrapi } from "~/utilities/strapi/serverStrapi";
import { useI18n } from "vue-i18n";

const { t, d } = useI18n();

const deliveryStatus = defineModel<OrderStatus>();

const customer = defineModel<SP8FeedbackCustomer>('customer');

const emit = defineEmits<{
    (event: 'fetchStateChange', state: FetchState): void
}>();

type FetchState = 'idle' | 'validation_error';

const fetchState = ref<FetchState>('idle');

watch(fetchState, (newState) => {
    emit('fetchStateChange', newState);
});

const fetchData = async () => {
    if (!deliveryStatus.value) return
    try {
        let result = await serverStrapi("create", "public-api/checkDeliveryQueue", { data: customer.value }) as { data: OrderStatus };
        fetchState.value = result.data.errorMessages ? 'validation_error' : 'idle';
        deliveryStatus.value = result.data;
        console.log(deliveryStatus.value);
    } catch (error) {
        fetchState.value = 'validation_error';
        console.error(error);
    }
};
defineExpose({ fetchData });
</script>

<template>
    <VCard v-if="deliveryStatus" class="mt-4 rounded-lg border">
        <VCardItem class="pt-2 px-2 pb-0">
            <VAlert class="rounded-lg">
                <h4 class="text-body-1 font-weight-bold">
                    Auslieferungsstatus deiner Bestellung
                </h4>
            </VAlert>
        </VCardItem>
        <VCardItem v-if="deliveryStatus.state === 'closed'">
            <template #prepend>
                <VIcon class="opacity-60 mr-1" icon="mdi-progress-check" size="x-large"></VIcon>
            </template>
            <template #title>
                <div class="text-body-1 text-wrap">
                    Dein Gerät ist bereits auf dem Weg zu dir – wir wünschen dir viel Freude damit!
                </div>
            </template>
        </VCardItem>
        <VCardItem v-else>
            <template #prepend>
                <VIcon class="opacity-60 mr-1" icon="mdi-progress-upload" size="x-large"></VIcon>
            </template>
            <template #title>
                <VRow>
                    <VCol cols="12" v-if="deliveryStatus.priority">
                        <div class="mr-1 opacity-80 text-caption">
                            Kategorie:
                        </div>
                        <div class="text-body-1">
                            {{ t('_orderStatus.priority.' + deliveryStatus.priority) }}
                        </div>
                    </VCol>
                    <VCol cols="12">
                        <div v-if="deliveryStatus.priority !== Priority.Share && deliveryStatus.priority !== Priority.Wait && deliveryStatus.priority !== Priority.BStockOption && fetchState !== 'validation_error'"
                            class="mt-1 mr-1 opacity-80 text-caption">
                            Reihenfolge:*
                        </div>
                        <div class="text-body-1" :class="{ 'opacity-60': deliveryStatus.rank === undefined }">
                            <template v-if="fetchState === 'idle'">
                                <template v-if="deliveryStatus.rank === undefined">
                                    konnte nicht ermittelt werden
                                </template>
                                <template v-else-if="deliveryStatus.rank === 0">
                                    <div class="text-wrap">
                                        Dein Gerät wird gerade für den Versand vorbereitet!
                                        {{ deliveryStatus.shipmentInfoText }}
                                    </div>
                                </template>
                                <template v-else>
                                    <template
                                        v-if="deliveryStatus.priority !== Priority.Share && deliveryStatus.priority !== Priority.Wait && deliveryStatus.priority !== Priority.BStockOption">
                                        {{ `noch ${deliveryStatus.rank} ${deliveryStatus.rank === 1 ? 'Block' :
        'Blöcke'} vor dir ` }}
                                        {{ deliveryStatus.shipmentInfoText }}
                                    </template>
                                </template>
                            </template>
                            <template v-else>
                                konnte nicht ermittelt werden
                            </template>
                        </div>
                    </VCol>
                </VRow>
            </template>
        </VCardItem>
        <VCardItem v-if="deliveryStatus.orderBlockedBy && deliveryStatus.priority != Priority.Share" class="pt-0 px-2">
            <template #prepend>
            </template>
            <template #title>
                <VRow>
                    <VCol cols="12">
                        <div class="mt-1 mr-1 opacity-80 text-caption pb-2 d-flex align-center">
                            <VIcon class="opacity-60 mr-3 " icon="mdi-alert-circle-outline" color="warning" :size="28">
                            </VIcon>
                            Hinweis:
                        </div>
                        <div class="text-body-1 text-wrap" v-html="t('_Form.DeliveryState.blockedBy.' + deliveryStatus.orderBlockedBy, {
        device: deliveryStatus.loadDevice,
        link: deliveryStatus.instructionsUrl
    })">
                        </div>
                    </VCol>
                </VRow>
            </template>
        </VCardItem>
        <VCardText v-if="deliveryStatus.state !== 'closed'" class="pt-4  bg-surface-light">
            <div class="text-body-1">
                <div v-if="deliveryStatus.priority === Priority.Wait">
                    <p>
                        Vielen Dank für deine Solidarität mit der Community. Weil du anderen den Vortritt lässt, können
                        wir
                        Menschen mit dringenden Fällen vorziehen und ihnen ermöglichen, ihr SHIFTphone 8.1 schneller zu
                        erhalten.
                    </p>
                </div>
                <div v-else-if="deliveryStatus.priority === Priority.Normal">
                    <p>
                        Vielen Dank für deine Unterstützung! Wir wissen deine Geduld sehr zu schätzen und
                        berücksichtigen deine
                        Bestellung im Rahmen der chronologisch eingegangenen Bestelldaten.
                    </p>
                    <br>
                    <p>
                        Für den Versand planen wir mit Auslieferungsblöcken, für die wir einen Umfang von jeweils 50
                        Geräten
                        vorsehen. Anhand des oben angezeigten Auslieferungsstatus kannst du ablesen, wie viele
                        Versandblöcke
                        noch vor dem Versandblock liegen, in dem sich dein SHIFTphone befindet.
                    </p>
                </div>
                <div v-else-if="deliveryStatus.priority === Priority.BStockOption">
                    Wir haben dein Interesse an einem SHIFTphone 8.0 B-Ware-Gerät vermerkt und melden uns mit weiteren
                    Informationen bei dir.
                </div>
                <div v-else-if="deliveryStatus.priority === Priority.Urgent">
                    <p>
                        Dank unseres solidarischen Auslieferungskonzepts können wir deine Bestellung mit Priorität
                        behandeln und
                        bemühen uns, dein Gerät so schnell wie möglich zu dir zu bringen. Bitte hab Verständnis, dass
                        die
                        Bearbeitungszeit dennoch variieren kann – je nach Bestelldatum und Anzahl der dringenden Fälle.
                        Falls
                        noch mehrere Blöcke vor dem Versand deines Geräte liegen, könnte auch unsere
                        Notfallkontingent-B-Ware-Option (siehe unten) eine weitere Möglichkeit für dich sein, über die
                        ein
                        sofortiger Versand möglich wäre.
                    </p>
                    <br>
                    <p>
                        Für den Versand planen wir mit Auslieferungsblöcken, für die wir einen Umfang von jeweils 50
                        Geräten
                        vorsehen. Anhand des oben angezeigten Auslieferungsstatus kannst du ablesen, wie viele
                        Versandblöcke
                        noch vor dem Versandblock liegen, in dem sich dein SHIFTphone befindet.
                    </p>
                </div>
                <div v-else-if="deliveryStatus.priority === Priority.Share">
                    <p>
                        Wir freuen uns, wenn du uns mit dem SHIFTphone 8 share unterstützt. Damit hilfst du uns in der
                        aktuellen
                        Finanzierungsphase und kannst später entscheiden, ob du das Gerät doch erhalten, in ein anderes
                        Produkt
                        umwandeln oder dein Geld zurückerstattet haben möchtest. Wir kontaktieren dich, wenn sich die
                        PreOrder-Auslieferungsphase dem Ende nähert.
                    </p>
                </div>
                <div v-else>
                    Bitte beachte, dass die Auslieferungsreihenfolge nur einmal täglich aktualisiert wird.
                </div>
            </div>
        </VCardText>
    </VCard>
    <template v-if="deliveryStatus">
        <div v-if="deliveryStatus.priority !== Priority.Share && deliveryStatus.priority !== Priority.Wait && deliveryStatus.priority !== Priority.BStockOption && fetchState !== 'validation_error' && deliveryStatus?.state !== 'closed' && deliveryStatus?.state !== 'cancelled'"
            class="text-caption ml-1 mt-1 text-grey">
            * Die Blöcke werden täglich gegen frühen Nachmittag aktualisiert.
        </div>
    </template>
</template>

<style scoped>
.animated-dots::after {
    content: '';
    animation: dots 2s infinite;
}

@keyframes dots {

    0%,
    20% {
        content: '';
    }

    40% {
        content: '.';
    }

    60% {
        content: '..';
    }

    80%,
    100% {
        content: '...';
    }
}
</style>